import React from "react";
import "./lokasi.scss";
import mapsimage from "../../media/Screenshot 2024-07-19 195747.png";
import mobilemaps from "../../media/Screenshot 2024-07-19 200511.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faGraduationCap,
  faHouse,
  faMapPin,
} from "@fortawesome/free-solid-svg-icons";

const lokasi = () => {
  return (
    <div id="lokasi" className="lokasi">
      <div className="judul">Lokasi</div>
      <div className="maps-lokasi">
        <img className="img-lokasi-dekstop" src={mapsimage} alt="maps" />
        <img className="img-lokasi-mobile" src={mobilemaps} alt="maps" />
      </div>
    </div>
  );
};

export default lokasi;
